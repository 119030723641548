<template>
  <div class="make-content-box">
    <div class="make-content">
      <div class="make-content-header">
        <div class="header-left">
          <i class="iconfont">&#xeb29;</i>
        </div>
        <div class="header-right">
          <span>1.审核时效，资质初审7个工作日，复审15个工作日，店铺授权1~3个工作日。</span>
          <span>2.你可登录查看审核状态。也可通过商务联系人邮箱了解入驻进度。</span>
          <span>3.店铺开通后，登录商家管理后台的用户名和密码会发送到您入驻时填写的联系人邮箱中。入驻过程中如有疑问请咨询客服电话<strong>4006229068</strong></span>
        </div>
      </div>
      <div class="make-content-body">
        <div class="body-contact-information">
          <div class="contact-information-title">
            联系信息
          </div>
          <div class="contact-information-message">
            <span>主体名称：{{corporate_name}}</span>
            <span>主体证件号：{{legal_representative_certificates_num}}</span>
            <span>联系人姓名：{{contact_name}}</span>
            <span>联系人手机：{{contact_phone}}</span>
            <span>联系人邮箱：{{contact_email}}</span>
          </div>
        </div>
        <div class="body-enter-state">
          <div class="enter-state-title">
            入驻状态
          </div>
          <div class="state-title-message">
            <div class="title-message-left">
              <i class="iconfont">&#xe656;</i>
              <span>已通过</span>
            </div>
            <div class="title-message-middle">
              <span>贵公司资质信息已提交完成，京东会尽快安排第三方公司为您进行资质审核。</span>
              <span>安装京麦APP，可随时查询入驻审核进度！</span>
            </div>
            <div class="title-message-right">
              <el-button type="primary" @click="toStoreSet">店铺设置</el-button>
            </div>
          </div>
        </div>
        <div class="body-audit-journal">
          <div class="audit-journal-title">
            审核日志
          </div>
          <div class="audit-journal-message">
            <el-table :data="auditList" style="width: 100%; flex: 1;border: 1px solid #EBEEF5" size="medium"
                      :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#D7F4ED',fontSize: '14px',height:'50px'}"
                      :cell-style="{fontSize: '14px',color: '#333',height: '60px', background:'#F2F9FA'}">
              <el-table-column prop="audit" label="入驻审核" align="center" width="236"></el-table-column>
              <el-table-column prop="time" label="操作时间" align="center" width="236"></el-table-column>
              <el-table-column prop="result" label="结果" align="center"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {storeinfoMain} from '@/utils/apis'
export default {
  name: "SubmitAudit",
  props:['stepValue'],
  data(){
    return {
      auditList:[
        {
          audit:'商家提交申请',
          time:'',
          result:'提交资质审核'
        }
      ],
      contact_email:'', //联系人邮箱
      contact_name:'', //联系人姓名
      contact_phone:'', //联系人手机
      corporate_name:'', //公司名字
      legal_representative_certificates_num:'', //证件号
      courseId:this.$route.query.course_id || null,
      opId:this.$route.query.id || null,
    }
  },
  mounted() {
    this.getStoreinfoMain()
  },
  methods: {
    // 获取提交审核信息
    getStoreinfoMain(){
      let params = {};
      if(this.opId){
        params.course_id = this.courseId;
        params.op_id = this.opId
      }
      storeinfoMain(params).then((res)=>{
        this.contact_email = res.data.contact_email;
        this.contact_name = res.data.contact_name;
        this.contact_phone = res.data.contact_phone;
        this.corporate_name = res.data.corporate_name;
        this.legal_representative_certificates_num = res.data.legal_representative_certificates_num;
        this.auditList[0].time = res.data.update_time;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 店铺设置
    toStoreSet(){
      this.$emit('update:stepValue', 6)
    }
  }
}
</script>

<style scoped lang="scss">
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px 97px 0 66px;
    .make-content-header{
      width: 100%;
      background: #FFFDF0;
      border: 1px solid #FFC8A0;
      box-sizing: border-box;
      display: flex;
      padding: 24px 36px;
      .header-left{
        display: flex;
        align-items: center;
        i{
          font-size: 36px;
          color: #FF9450;
        }
      }
      .header-right{
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        span{
          font-size: 14px;
          color: #FF9450;
          font-weight: 400;
          line-height: 30px;
          strong{
            font-weight: 400;
            color: #2461EF;
          }
        }
      }
    }
    .make-content-body{
      display: flex;
      flex-direction: column;
      .body-contact-information{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .contact-information-title{
          font-size: 16px;
          line-height: 1;
        }
        .contact-information-message{
          margin-top: 18px;
          border: 1px solid #E5E5E5;
          background: #F5F7F6;
          display: flex;
          flex-direction: column;
          padding: 10px 38px;
          span{
            line-height: 24px;
            color: #333333;
            font-size: 14px;
          }
        }
      }
      .body-enter-state{
        display: flex;
        flex-direction: column;
        margin-top: 35px;
        .enter-state-title{
          font-size: 16px;
          line-height: 1;
        }
        .state-title-message{
          margin-top: 18px;
          display: flex;
          //justify-content: space-between;
          border: 1px solid #2DC079;
          background: #E7F6EF;
          padding: 16px 76px 16px 36px;
          .title-message-left{
            display: flex;
            align-items: center;
            i{
              font-size: 30px;
              line-height: 1;
              color: #2DC079;
            }
            span{
              font-size: 18px;
              margin-left: 14px;
            }
          }
          .title-message-middle{
            display: flex;
            flex-direction: column;
            margin-left: 38px;
            span{
              font-size: 14px;
              line-height: 24px;
            }
          }
          .title-message-right{
            flex: 1;
            width: 1%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            .el-button{
              padding: 12px;
              width: 88px;
            }
          }
        }
      }
      .body-audit-journal{
        display: flex;
        flex-direction: column;
        margin-top: 54px;
        .audit-journal-title{
          font-size: 16px;
          line-height: 1;
        }
        .audit-journal-message{
          margin: 30px 0;
          ::v-deep .el-table{
            .el-table__header-wrapper .has-gutter tr {
              .is-leaf{
                &:first-of-type{
                  border-right: 1px solid #fff;
                }
                &:nth-child(2){
                  border-right: 2px solid #fff;
                }
              }
            }
            .el-table__body-wrapper .el-table__body .el-table__row .is-center {
              &:first-of-type{
                border-right: 1px solid #fff;
              }
              &:nth-child(2){
                border-right: 2px solid #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>