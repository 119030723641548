<template>
  <div class="make-content-box">
    <div class="make-content">
      <div class="make-content-header">
        <span>1.信息提交前，请务必先了解<strong>京东开发平台招商资质标准细则；</strong></span>
        <span>2.公司信息需要项较多，建议先查看<strong>公司信息注意事项</strong>再进行填写；</span>
        <span>3.公司信息请严格按照相关证件信息进行确认填写；</span>
        <span>4.以下所需要上传电子版资质仅支持jpg、gif、png格式的图片，大小不超过1M，且必须加盖企业彩色公章。</span>
      </div>
      <div class="make-content-body">
        <div class="content-body-title">税务登记证</div>
        <div class="content-body-form">
          <el-form :model="form" :rules="rules" ref="form" label-width="178px">
            <el-form-item label="纳税人类型：" prop="taxpayers_type">
              <el-select v-model="form.taxpayers_type" style="width: 380px;" placeholder="请选择">
                <el-option v-for="item in taxpayersList" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="纳税人识别号：" prop="taxpayers_identity_num">
              <el-input placeholder="15位的数字" style="width: 380px;" v-model.number="form.taxpayers_identity_num"></el-input>
              <div class="form-item-message">三证合一的请填写社会信用代码，一般纳税人税码为13%，小规模税码为3%</div>
            </el-form-item>
            <el-form-item label="纳税人类型税码：" prop="yax_type">
              <el-select v-model="form.yax_type" style="width: 380px;" placeholder="请选择">
                <el-option v-for="item in yaxList" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="税务登记电子版：" prop="tax_pdf_src">
              <div class="tax-administration-src">
                <span>请同时上传国税、地税的税务登记证，两者缺一不可，复印件加盖公司红章。<strong>【国地税合一示例】【国地税非合一示例】</strong></span>
                <span>如贵司所在地区已推行“三证合一”此处请上传营业执照副本电子版。</span>
                <div class="upload-img-box">
                  <div class="upload-img" v-if="form.tax_pdf_src">
                    <img :src="hosturl + form.tax_pdf_src" alt="">
                    <i class="iconfont" @click="deleteTaxPdfSrc">&#xe651;</i>
                  </div>
                  <div class="upload-box" onclick="taxPdfSrc.click()">
                    <i class="iconfont">&#xe609;</i>
                  </div>
                  <input
                      ref="modelTaxPdfSrcInput"
                      type="file"
                      id="taxPdfSrc"
                      @change="uploadTaxPdfSrc($event)"
                      style="display:none"
                      accept=".jpg,.jpeg,.gif,.png,"
                  />
                </div>
                <span>图片尺寸请确保800px*800px以上，文件大小在5MB以内，支持jpg、gif、png格式，最多可上传1张</span>
              </div>
            </el-form-item>
            <el-form-item label="一般纳税人资格证电子版：" class="tax-e-src-class" prop="tax_e_src">
              <div class="tax-administration-src">
                <span>三证合一地区请上传税务局网站上一般纳税人截图，复印件请加盖开店公司红章</span>
                <div class="upload-img-box" style="margin-top: 0">
                  <div class="upload-img" v-if="form.tax_e_src">
                    <img :src="hosturl + form.tax_e_src" alt="">
                    <i class="iconfont"  @click="deleteTaxESrc">&#xe651;</i>
                  </div>
                  <div class="upload-box" onclick="taxESrc.click()">
                    <i class="iconfont">&#xe609;</i>
                  </div>
                  <input
                      ref="modelTaxESrcInput"
                      type="file"
                      id="taxESrc"
                      @change="uploadTaxESrc($event)"
                      style="display:none"
                      accept=".jpg,.jpeg,.gif,.png,"
                  />
                </div>
                <span>图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持jpg、gif、png格式，最多可上传1张</span>
              </div>
<!--              <div class="cut-off-line"></div>-->
            </el-form-item>
            <div class="content-body-title2">银行账号信息</div>
            <el-form-item label="银行开户名：">
              <span style="color: #333333">一课教育</span>
            </el-form-item>
            <el-form-item label="对公结算银行账号：" prop="corporate_settlement_bank_account">
              <el-input  style="width: 380px;" v-model="form.corporate_settlement_bank_account"></el-input>
              <div class="form-item-message">用于京东平台与开店公司间账务结算</div>
            </el-form-item>
            <el-form-item label="支行联行号：" prop="branch_bank_account">
              <el-input  style="width: 380px;" v-model="form.branch_bank_account"></el-input>
              <div class="form-item-message">请咨询开户行客服获取联行号，通过联行号进行查询选择，若无区支行，可选择上一级市/生机支行</div>
            </el-form-item>
            <el-form-item label="支行名称：" prop="branch_bank_name">
              <el-input  style="width: 380px;" v-model="form.branch_bank_name"></el-input>
            </el-form-item>
            <el-form-item label="开户银行支行所在地：" required class="select-address">
              <el-col>
                <el-form-item prop="open_account_bank_province">
                  <el-select style="width: 117px;" v-model="form.open_account_bank_province" @change="selectOpenAccountBank($event,1)" placeholder="选择省">
                    <el-option
                        v-for="item in openAccountBankProvince"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="open_account_bank_cit">
                  <el-select style="width: 117px;margin: 0 10px" v-model="form.open_account_bank_cit" @change="selectOpenAccountBank($event,2)" placeholder="选择市">
                    <el-option
                        v-for="item in openAccountBankCity"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="open_account_bank_area">
                  <el-select style="width: 117px;" v-model="form.open_account_bank_area" @change="selectOpenAccountBank($event,3)" placeholder="选择区">
                    <el-option
                        v-for="item in openAccountBankArea"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="footer-btn">
      <el-button @click="lastStep">上一步</el-button>
      <el-button @click="nextStep('form')" type="primary" style="margin-left: 20px">下一步，完善店铺信息</el-button>
    </div>
  </div>
</template>

<script>
import {storesettingUpload, storesettingThree, storesettingAddStepThree} from '@/utils/apis'
import {areaList} from '@/utils/area'
export default {
  name: "BodyMessageThree",
  props:['stepValue'],
  data(){
    // 验证纳税人识别号
    var isTaxpayersIdentityNum = (rule, value, callback) => {
      if(value === ''){
        callback(new Error('请输入纳税人识别号'));
      } else {
        if(!Number.isInteger(value)){
          callback(new Error('输入格式为纯数字'));
        } else {
          let str = '' + value;
          if(str.length !== 15){
            callback(new Error('长度为15位'));
          } else {
            callback();
          }
        }
      }
    };
    // 效验上传税务登记电子版
    var isTaxPdfSrc = (rule, value, callback) => {
      if(!this.form.tax_pdf_src){
        callback(new Error("请上传税务登记电子版"));
      } else {
        callback();
      }
    };
    // 效验上传一般纳税人资格证电子版
    var isTaxESrc = (rule, value, callback) => {
      if(!this.form.tax_e_src){
        callback(new Error("请上传一般纳税人资格证电子版"));
      } else {
        callback();
      }
    };
    // 效验对公结算银行账号是否正整数
    var isCorporateNumber = (rule,value,callback)=>{
      let ret=/^([0-9]*)$/;
      if(value===''){
        callback(new Error('请输入对公结算银行账号'))
      }else if(!ret.test(this.form.corporate_settlement_bank_account)){
        callback(new Error('请输入正整数'))
      }else{
        callback()
      }
    };
    // 效验支行联行号是否正整数
    var isBranch = (rule,value,callback)=>{
      let ret=/^([0-9]*)$/;
      if(value===''){
        callback(new Error('请输入对公结算银行账号'))
      }else if(!ret.test(this.form.branch_bank_account)){
        callback(new Error('请输入正整数'))
      }else{
        callback()
      }
    }
    return {
      form:{
        id:'',
        taxpayers_type:'',
        taxpayers_identity_num:'',
        yax_type:'',
        tax_pdf_src:'',
        tax_e_src:'',
        corporate_settlement_bank_account:'',
        branch_bank_account:'',
        branch_bank_name:'',
        open_account_bank_province:'',
        open_account_bank_cit:'',
        open_account_bank_area:'',
      },
      rules:{
        taxpayers_type:[
          { required: true, message: '请选择纳税人类型', trigger: 'change' }
        ],
        taxpayers_identity_num:[
          { required: true, validator:isTaxpayersIdentityNum, trigger: 'blur'}
        ],
        yax_type:[
          { required: true, message: '请选择纳税人类型税码', trigger: 'change' }
        ],
        tax_pdf_src:[
          { required: true, validator: isTaxPdfSrc,}
        ],
        tax_e_src:[
          { required: true, validator: isTaxESrc,}
        ],
        corporate_settlement_bank_account:[
          { required: true, validator:isCorporateNumber, trigger: 'blur',}
        ],
        branch_bank_account:[
          { required: true, validator:isBranch, trigger: 'blur',}
        ],
        branch_bank_name:[
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        open_account_bank_province:[
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        open_account_bank_cit:[
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        open_account_bank_area:[
          { required: true, message: '请选择区', trigger: 'change' }
        ],
      },
      // 纳税人类型
      taxpayersList:[
        {
          id:0,
          name:'一般纳税人'
        },
        {
          id:1,
          name:'小规模纳税人'
        },
        {
          id:2,
          name:'非增值税纳税人'
        },
      ],
      // 纳税人类型税码
      yaxList:[
        {
          id:0,
          name:'0%'
        },
        {
          id:1,
          name:'1%'
        },
        {
          id:2,
          name:'3%'
        },
        {
          id:3,
          name:'6%'
        },
        {
          id:4,
          name:'7%'
        },
        {
          id:5,
          name:'9%'
        },
        {
          id:6,
          name:'10%'
        },
        {
          id:7,
          name:'13%'
        },
        {
          id:8,
          name:'图书9%免税'
        },
      ],
      hosturl:localStorage.getItem('hosturl') || null,
      // 开户银行支行选择地区
      openAccountBankProvince:[],
      openAccountBankCity:[],
      openAccountBankArea:[],
      courseId:this.$route.query.course_id || null,
      opId:this.$route.query.id || null,
    }
  },
  mounted() {
    areaList.forEach(item=>{
      this.openAccountBankProvince.push(item);
    })
    this.getStoresettingThree();
  },
  methods:{
    // 获取学生主体信息3的答题内容
    getStoresettingThree(){
      let params = {};
      if(this.opId){
        params.course_id = this.courseId;
        params.op_id = this.opId
      }
      storesettingThree(params).then((res)=>{
        if(res.data.id){
          for(let i in this.form){
            if(i === 'taxpayers_identity_num'){
              this.form[i] = Number(res.data[i])
            } else {
              this.form[i] = res.data[i]
            }
          }
          this.selectOpenAccountBank(res.data.open_account_bank_province,1);
          this.form.open_account_bank_province = res.data.open_account_bank_province;
          this.selectOpenAccountBank(res.data.open_account_bank_cit,2);
          this.form.open_account_bank_cit = res.data.open_account_bank_cit;
          this.form.open_account_bank_area = res.data.open_account_bank_area
        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 上传税务登记电子版
    uploadTaxPdfSrc(event){
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append('certificate', file);
      storesettingUpload(formData).then((res)=>{
        this.$message.success(res.msg);
        this.form.tax_pdf_src = res.data.src;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 删除税务登记电子版
    deleteTaxPdfSrc(){
      this.form.tax_pdf_src = ''
    },
    // 上传一般纳税人资格证电子版
    uploadTaxESrc(event){
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append('certificate', file);
      storesettingUpload(formData).then((res)=>{
        this.$message.success(res.msg);
        this.form.tax_e_src = res.data.src;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 删除一般纳税人资格证电子版
    deleteTaxESrc(){
      this.form.tax_e_src = ''
    },
    //开户银行支行所在地-选择地区
    selectOpenAccountBank(val,type){
      if(type === 1){
        this.form.open_account_bank_cit = '';
        this.form.open_account_bank_area = '';
        this.openAccountBankCity = [];
        this.openAccountBankArea = [];
        let length = this.openAccountBankProvince.length;
        for(let i = 0; i < length; i++){
          if(this.openAccountBankProvince[i].name === val){
            this.openAccountBankCity = this.openAccountBankProvince[i].city;
            return
          }
        }
      } else if(type === 2){
        this.form.open_account_bank_area = '';
        this.openAccountBankArea = [];
        let length = this.openAccountBankCity.length;
        for(let i = 0; i < length;i++){
          if(this.openAccountBankCity[i].name === val){
            this.openAccountBankArea = this.openAccountBankCity[i].area
            return
          }
        }
      }
    },
    lastStep(){
      this.$emit('update:stepValue',2.1)
    },
    nextStep(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('taxpayers_type', this.form.taxpayers_type);
          formData.append('taxpayers_identity_num', this.form.taxpayers_identity_num);
          formData.append('yax_type', this.form.yax_type);
          formData.append('tax_pdf_src', this.form.tax_pdf_src);
          formData.append('tax_e_src', this.form.tax_e_src);
          formData.append('corporate_settlement_bank_account', this.form.corporate_settlement_bank_account);
          formData.append('branch_bank_account', this.form.branch_bank_account);
          formData.append('branch_bank_name', this.form.branch_bank_name);
          formData.append('open_account_bank_province', this.form.open_account_bank_province);
          formData.append('open_account_bank_cit', this.form.open_account_bank_cit);
          formData.append('open_account_bank_area', this.form.open_account_bank_area);
          if(this.form.id){
            formData.append('id', this.form.id);
          }
          if(this.opId){
            formData.append('course_id', this.courseId);
            formData.append('op_id', this.opId);
          }
          storesettingAddStepThree(formData).then((res)=>{
            this.$emit('update:stepValue',3)
          }).catch((err)=>{
            console.log('err',err)
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px 97px 0 66px;
    .make-content-header{
      width: 100%;
      background: #FFFDF0;
      border: 1px solid #FFC8A0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 8px 46px;
      span{
        font-size: 14px;
        color: #333;
        font-weight: 400;
        line-height: 30px;
        strong{
          font-weight: 400;
          color: #2461EF;
        }
      }
    }
    .make-content-body{
      display: flex;
      flex-direction: column;
      .content-body-title{
        font-size: 16px;
        line-height: 1;
        margin: 38px 0 0 0;
      }
      .content-body-title2{
        font-size: 16px;
        line-height: 1;
        padding: 30px 0 8px;
      }
      .content-body-form{
        margin: 29px 0 0 15px;
        ::v-deep .el-form{
          .el-form-item{
            .el-form-item__label{
              padding: 0;
            }
          }
          .form-item-message{
            line-height: 1;
            font-size: 12px;
            color: #999999;
            padding: 10px 0 4px;
          }
        }
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
.tax-administration-src{
  display: flex;
  flex-direction: column;
  span{
    //line-height: 1;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    &:nth-child(2){
      line-height: 1;
    }
    &:last-of-type{
      padding: 10px 0 4px;
      color: #999999;
      font-size: 12px;
      line-height: 1;
    }
    strong{
      font-weight: 400;
      color: #2461EF;
    }
  }
  .upload-img-box{
    margin-top: 10px;
    display: flex;
    .upload-img, .upload-box{
      width: 120px;
      height: 120px;
      &.upload-img{
        margin-right: 40px;
        position: relative;
        i{
          line-height: 1;
          font-size: 12px;
          color: #F44336;
          position: absolute;
          right: -20px;
          top: 0;
          cursor: pointer;
        }
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      &.upload-box{
        background: #F7F7F7;
        text-align: center;
        line-height: 120px;
        cursor: pointer;
        i{
          font-size: 24px;
          color: #BFBFBF;
        }
      }
    }
  }
}
.cut-off-line{
  height: 1px;
  border-bottom: 1px dashed #D7DDE4;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
::v-deep .tax-e-src-class{
  position: relative;
  margin-bottom: 40px;
  &::after{
    content: '';
    height: 1px;
    width: calc(100% + 177px);
    position: absolute;
    left: -81px;
    bottom: -40px;
    border-bottom: 1px dashed #D7DDE4;
  }
}
::v-deep .select-address .el-form-item__content {
  display: flex;
  flex-direction: column;
  .el-col{
    display: flex;
  }
}
</style>