<template>
  <div class="make-content-box">
    <div class="make-content">
      <div class="make-content-header">经营信息</div>
      <div class="make-content-form">
        <el-form :model="form" :rules="rules" ref="form" label-width="136px">
          <el-form-item label="公司类型：" prop="company_type">
            <el-select v-model="form.company_type" style="width: 380px;" placeholder="请选择公司类型">
              <el-option v-for="item in companyTypeList" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司网址：" prop="company_web">
            <el-input v-model="form.company_web" placeholder="非必填" style="width: 380px;"></el-input>
            <div class="form-item-message">形如：http://www.jd.com/</div>
          </el-form-item>
          <el-form-item label="年销售额（万元）：" prop="annual_sales">
            <el-input style="width: 380px;" v-model.number="form.annual_sales"></el-input>
            <div class="form-item-message">仅输入不得超过9位数的正整数</div>
          </el-form-item>
          <el-form-item label="商品数量：" prop="goods_num">
            <el-select v-model="form.goods_num" style="width: 380px;" placeholder="请选择商品数量">
              <el-option v-for="item in goodsNumList" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
            <div class="form-item-message">某一商品的多种颜色、尺码或包装形式不同时，按一件商品计算。</div>
          </el-form-item>
          <el-form-item label="平均客单价：" prop="avg_price">
            <el-select v-model="form.avg_price" style="width: 380px;" placeholder="请选择平均客单价">
              <el-option v-for="item in avgPriceList" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
            <div class="form-item-message">用户购物时，单词购买的平均金额。</div>
          </el-form-item>
          <el-form-item label="仓库情况：" prop="warehouse_situation">
            <el-select v-model="form.warehouse_situation" style="width: 380px;" placeholder="请选择仓库情况">
              <el-option v-for="item in warehouseSituationList" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="仓库地址：" prop="warehouse_address">
            <el-input v-model="form.warehouse_address" placeholder="非必填" style="width: 380px;"></el-input>
          </el-form-item>
          <el-form-item label="常用物流：" prop="common_logistics">
            <el-input v-model="form.common_logistics" style="width: 380px;"></el-input>
            <div class="form-item-message">可填写多个，用“#”分隔，如“申通#圆通”，最大50字</div>
          </el-form-item>
          <el-form-item label="ERP类型：" prop="ERP_type">
            <el-select v-model="form.ERP_type" style="width: 380px;" placeholder="请选择ERP类型">
              <el-option v-for="item in ERPTypeList" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="代运营公司：" prop="agent_operating_company">
            <el-input v-model="form.agent_operating_company" placeholder="非必填" style="width: 380px;"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer-btn">
      <el-button @click="lastStep">上一步</el-button>
      <el-button @click="nextStep('form')" type="primary" style="margin-left: 20px">下一步，完善店铺命名</el-button>
    </div>
  </div>
</template>

<script>
import {storeinfoInfo, storeinfoAdd} from '@/utils/apis'
export default {
  name: "StoreInformation",
  props:['stepValue'],
  data(){
    // 验证年销售额（万元)
    var isAnnualSales = (rule, value, callback) => {
      if(value === ''){
        callback(new Error('请输入年销售额'));
      } else {
        if(!Number.isInteger(value)){
          callback(new Error('输入格式为纯数字'));
        } else {
          let str = '' + value;
          if(str.length > 9){
            callback(new Error('仅输入不得超过9位数的正整数'));
          } else {
            callback();
          }
        }
      }
    }
    return {
      form:{
        id:'',
        company_type:'',
        company_web:'',
        annual_sales:'',
        goods_num:'',
        avg_price:'',
        warehouse_situation:'',
        warehouse_address:'',
        common_logistics:'',
        ERP_type:'',
        agent_operating_company:'',
      },
      rules:{
        company_type:[
          { required: true, message: '请选择公司类型', trigger: 'change' }
        ],
        annual_sales:[
          { required: true, validator:isAnnualSales, trigger: 'blur'}
        ],
        goods_num:[
          { required: true, message: '请选择商品数量', trigger: 'change' }
        ],
        avg_price:[
          { required: true, message: '请选择平均客单价', trigger: 'change' }
        ],
        warehouse_situation:[
          { required: true, message: '请选择仓库情况', trigger: 'change' }
        ],
        common_logistics:[
          { required: true, message: '请输入常用物流', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        ERP_type:[
          { required: true, message: '请选择ERP类型', trigger: 'change' }
        ]
      },
      // 公司类型list
      companyTypeList:[
        {
          id:0,
          name:'生产厂商'
        },
        {
          id:1,
          name:'品牌商'
        },
        {
          id:2,
          name:'代理商'
        },
        {
          id:3,
          name:'经销商'
        },
      ],
      // 商品数量list
      goodsNumList:[
        {
          id:0,
          name:'0-100'
        },
        {
          id:1,
          name:'100-200'
        },
        {
          id:2,
          name:'200-500'
        },
        {
          id:3,
          name:'大于500'
        },
      ],
      // 平均客单价list
      avgPriceList:[
        {
          id:0,
          name:'0-100'
        },
        {
          id:1,
          name:'100-200'
        },
        {
          id:2,
          name:'200-500'
        },
        {
          id:3,
          name:'大于500'
        },
      ],
      // 仓库情况list
      warehouseSituationList:[
        {
          id:0,
          name:'自有仓库'
        },
        {
          id:1,
          name:'第三方仓库'
        },
        {
          id:2,
          name:'无仓库'
        },
      ],
      // ERP类型list
      ERPTypeList:[
        {
          id:0,
          name:'自有ERP'
        },
        {
          id:1,
          name:'第三方ERP代运营'
        },
        {
          id:2,
          name:'无'
        },
      ],
      courseId:this.$route.query.course_id || null,
      opId:this.$route.query.id || null,
    }
  },
  mounted() {
    this.getStoreinfoInfo();
  },
  methods:{
    getStoreinfoInfo(){
      let params = {};
      if(this.opId){
        params.course_id = this.courseId;
        params.op_id = this.opId
      }
      storeinfoInfo(params).then((res)=>{
        if(res.data.id){
          for(let i in this.form){
            this.form[i] = res.data[i]
          }
        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    lastStep(){
      this.$emit('update:stepValue', 2.2)
    },
    nextStep(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('company_type', this.form.company_type);
          if(this.form.company_web){
            formData.append('company_web', this.form.company_web);
          }
          formData.append('annual_sales', this.form.annual_sales);
          formData.append('goods_num', this.form.goods_num);
          formData.append('avg_price', this.form.avg_price);
          formData.append('warehouse_situation', this.form.warehouse_situation);
          formData.append('common_logistics', this.form.common_logistics);
          formData.append('ERP_type', this.form.ERP_type);
          if(this.form.agent_operating_company){
            formData.append('agent_operating_company', this.form.agent_operating_company)
          }
          if(this.form.warehouse_address){
            formData.append('warehouse_address', this.form.warehouse_address)
          }
          if(this.form.id){
            formData.append('id', this.form.id)
          }
          if(this.opId){
            formData.append('course_id', this.courseId)
            formData.append('op_id', this.opId)
          }
          storeinfoAdd(formData).then((res)=>{
            this.$emit('update:stepValue', 4)
          }).catch((err)=>{
            console.log('err',err)
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 68px 0 0 104px;
    .make-content-header{
      font-size: 16px;
      line-height: 1;
    }
    .make-content-form{
      margin:30px 0 0px 32px;
      ::v-deep .el-form{
        .el-form-item{
          .el-form-item__label{
            padding: 0;
          }
        }
      }
      .form-item-message{
        line-height: 1;
        font-size: 12px;
        color: #999999;
        padding: 8px 0 4px;
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
</style>