<template>
    <div class="dialog-content">
        <el-dialog title="提示信息"
                   :visible.sync="successDialogVisible"
                   width="500px"
                   :before-close="handleClose">
            <div class="dialog-content">
                <i class="el-icon-success"></i>
                <span class="sucess-text">提交成功</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleClose">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "successDialog",
        props: ['successDialogVisible'],
        methods: {
            handleClose() {
                this.$emit('closeDialog', false)
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-dialog__wrapper {
        .el-dialog__header {
            background: #2DC079;

            span, i {
                font-size: 16px;
                color: #fff;
            }
        }

        .el-dialog__body {
            padding: 45px 0;
        }

        .el-dialog__footer {
            text-align: center;
        }
    }

    .dialog-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i {
            font-size: 50px;
            color: #2DC079;
        }

        .sucess-text {
            margin-top: 18px;
            font-size: 16px;
            color: #333333;
        }
    }

</style>