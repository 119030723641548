<template>
  <div class="make-content-box">
    <div class="make-content">
      <div class="make-content-header">
        入驻联系人信息
      </div>
      <div class="make-content-form">
        <el-form :model="form" :rules="rules" ref="form" label-width="136px">
          <el-form-item label="联系人姓名：" prop="contact_name">
            <el-input v-model="form.contact_name" style="width: 380px;"></el-input>
          </el-form-item>
          <el-form-item label="联系人手机：" prop="contact_phone">
            <el-input v-model="form.contact_phone" style="width: 380px;"></el-input>
          </el-form-item>
          <el-form-item label="联系人电子邮箱：" prop="contact_email">
            <el-input v-model="form.contact_email" style="width: 380px;"></el-input>
            <div class="form-item-message" style="margin-top: 10px">入驻过程中用于接收京东审核结果、开店账号密码信息，请务必仔细确认。</div>
            <div class="form-item-message" style="margin-top: 6px">建议使用QQ、网易邮箱、189、自主企业邮箱可能无法正常收取邮件。</div>
          </el-form-item>
          <el-form-item label="对接京东招商人员：" prop="is_jd_join">
            <el-radio-group v-model="form.is_jd_join">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
            </el-radio-group>
            <div class="form-item-message">如果没有联系过京东招商人员请选择“无”。</div>
          </el-form-item>
          <el-form-item label="姓名：" v-if="form.is_jd_join === 1" prop="name">
            <el-input v-model="form.name" style="width: 380px;"></el-input>
          </el-form-item>
          <el-form-item label="推荐码：" prop="recommend_code">
            <el-input v-model="form.recommend_code" placeholder="非必填" style="width: 380px;"></el-input>
            <div class="form-item-message" style="margin-top: 10px">请录入推荐商家的商家ID，由数字组成；如果没有受到邀请，则不用填写。</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer-btn">
      <el-button @click="lastStep">上一步</el-button>
      <el-button @click="nextStep('form')" type="primary" style="margin-left: 20px">下一步，完善主体信息</el-button>
    </div>
  </div>
</template>

<script>
import {storesettingInfo, storesettingAdd} from '@/utils/apis'
export default {
  name: "BodyMessageOne",
  props:['stepValue'],
  data(){
    // 手机号验证
    var isPhoneNum = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('手机号格式有误'))
      } else {
        callback()
      }
    };
    return {
      form:{
        id:'',
        contact_name:'',
        contact_phone:'',
        contact_email:'',
        is_jd_join:1,
        name:'',
        recommend_code:'',
      },
      rules:{
        contact_name:[
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        contact_phone:[
          { required: true, message: '请输入联系人手机', trigger: 'blur' },
          { validator:isPhoneNum,  trigger: 'blur'}
        ],
        contact_email:[
          { required: true, message: '请输入联系人电子邮箱', trigger: 'blur' },
          {type: 'email', message: '请输入正确的邮箱', trigger: 'blur'}
        ],
        is_jd_join:[
          { required: true, message: '请选择是否有无对接京东招商人员', trigger: 'change' }
        ],
        name:[
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ]
      },
      courseId:this.$route.query.course_id || null,
      opId:this.$route.query.id || null,
    }
  },
  mounted() {
    this.getStoresettingInfo();
  },
  methods:{
    // 获取学生主体信息1答题
    getStoresettingInfo(){
      let params = {};
      if(this.opId){
        params.course_id = this.courseId;
        params.op_id = this.opId
      }
      storesettingInfo(params).then((res)=>{
        if(res.data.id){
          this.form.id = res.data.id;
          this.form.contact_name = res.data.contact_name;
          this.form.contact_phone = res.data.contact_phone;
          this.form.contact_email = res.data.contact_email;
          this.form.is_jd_join = res.data.is_jd_join;
          if(res.data.name){
            this.form.name = res.data.name;
          }
          if(res.data.recommend_code){
            this.form.recommend_code = res.data.recommend_code;
          }
        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    lastStep(){
      this.$emit('update:stepValue',1)
    },
    nextStep(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('contact_name', this.form.contact_name);
          formData.append('contact_phone', this.form.contact_phone);
          formData.append('contact_email', this.form.contact_email);
          formData.append('is_jd_join', this.form.is_jd_join);
          if(this.form.is_jd_join === 1){
            formData.append('name', this.form.name);
          }
          if(this.form.recommend_code){
            formData.append('recommend_code', this.form.recommend_code);
          }
          if(this.form.id){
            formData.append('id', this.form.id);
          }
          if(this.opId){
            formData.append('course_id', this.courseId);
            formData.append('op_id', this.opId);
          }
          storesettingAdd(formData).then((res)=>{
            this.$emit('update:stepValue',2.1)
          }).catch((err)=>{
            console.log('err',err)
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.make-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  .make-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 68px 0 0 78px;
    .make-content-header{
      font-size: 16px;
      line-height: 1;
    }
    .make-content-form{
      margin:29px 0 286px 29px;
      ::v-deep .el-form{
        .el-form-item{
          .el-form-item__label{
            padding: 0;
          }
        }
      }
      .form-item-message{
        line-height: 1;
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .footer-btn{
    padding: 10px 72px 10px 0;
    box-sizing: border-box;
    text-align: right;
    border-top: solid #DCDCDC 1px;
  }
}
</style>